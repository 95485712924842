<template>
  <b-overlay :show="isLoading">
    <b-row>
      <b-col>
        <h1>Mail templates</h1>
      </b-col>
      <b-col cols="3" align-self="center">
        <b-alert
          align-v="center"
          variant="warning"
          :show="saveMessage !== ''"
          >{{ this.saveMessage }}</b-alert
        >
      </b-col>
      <b-col cols="auto">
        <!-- Button -->
        <!-- <b-button :disabled="saveDisabled" variant="primary" @click="doSave">
          Save
        </b-button> -->
      </b-col>
    </b-row>
    <b-button class="mb-2 mt-2 add-button" @click="addMail()"> Add </b-button>
    <vuetable
      ref="mailstable"
      :api-mode="false"
      :fields="['subject', 'action_url', 'created_at', 'actions']"
      :data="mails"
    >
      <!-- <template slot="active" scope="props">
            <b-form-checkbox v-model="attachments.data[props.rowIndex].active" name="active" class="content-switch" disabled></b-form-checkbox>
          </template> -->
      <template slot="actions" scope="props">
        <font-awesome-icon
          icon="edit"
          class="action-icon"
          data-toggle="tooltip"
          data-placement="top"
          title="Edit item"
          @click="editMail(props.rowIndex)"
          ref="btnEdit"
        ></font-awesome-icon>
        <font-awesome-icon
          icon="trash"
          class="ml-2 action-icon"
          data-toggle="tooltip"
          data-placement="top"
          title="Remove item"
          @click="deleteMail(props.rowData, props.rowIndex)"
        ></font-awesome-icon>
      </template>
    </vuetable>
  </b-overlay>
</template>
<script>
import Vuetable from "vuetable-2";

export default {
  components: {
    Vuetable,
  },
  data() {
    return {
      isLoading: false,
      selectedMail: null,
      showModal: false,
      mailIndex: null,
      saveDisabled: true,
      saveMessage: "",
    };
  },
  async created() {
    this.selectedMail = null;
    this.showModal = "";
    await this.$store.dispatch("Mails/fetchAll");

    this.saveDisabled = true;
    this.saveMessage = "";
  },
  watch: {
    mails: {
      handler() {
        this.saveMessage = "You have unsaved changes!";
        this.saveDisabled = false;
      },
      deep: true,
    },
  },
  computed: {
    mails() {
      return this.$store.state.Mails.mails;
    },
  },
  methods: {
    async doSave() {
      this.isLoading = true;
      await this.$store.dispatch("Mails/update", this.mails);
      await this.$store.dispatch("Mails/fetchAll");
      this.$root.$bvToast.toast("Mails settings saved!", {
        variant: "success",
      });
      this.saveDisabled = true;
      this.saveMessage = "";
      this.isLoading = false;
    },
    addMail() {
      this.$router.push({ path: "mail-template-detail" });
    },
    editMail(index) {
      this.$router.push({
        path: "mail-template-detail",
        query: { id: this.mails.data[index].id },
      });
    },
    async deleteMail(mail, index) {
      this.isLoading = true;
      const isConfirmed = await this.$bvModal.msgBoxConfirm(
        `Are you sure you want to remove mail "${mail.subject}"?`,
        {
          title: "Confirm delete",
          okVariant: "danger",
          centered: true,
        }
      );
      if (isConfirmed) {
        this.mails.splice(index, 1);
        await this.$store.dispatch("Mails/delete", mail);
      }
      this.isLoading = false;
    },
    toggleModal() {
      this.showModal = !this.showModal;
    },
  },
};
</script>
<style lang="scss">
$color-primary: var(--primary);
$color-secondary: var(--secondary);
$text-color-header: var(--theme-color-text_color_header);
$text-color-title: var(--theme-color-text_color_title);
$text-color-body: var(--theme-color-text_color_body);
$font-family: var(--theme-font-family);
$background-image: var(--theme-background-image);

.action-icon:hover {
  color: $color-secondary;
  cursor: pointer;
}
</style>
